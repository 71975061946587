import dayjs from 'dayjs'
import pluralize from 'pluralize'
import { type ChangeEvent, type ReactNode, useCallback, useState } from 'react'
import toast from 'react-hot-toast'

import { submitForm } from '@fv/client-core'
import {
  type AwardLoadsDTO,
  type LoadDTO,
  type UIQuote,
} from '@fv/client-types'
import { type Country } from '@fv/models'

import { refrigeratedEquipmentTypes, supportMessage } from '../../constants'
import { useQueryParam } from '../../hooks/settings'
import { type AwardChangeReason } from '../../hooks/shipments/useRetenderLoad'
import { removeNullProperties } from '../../utils/removeNullProperties'
import { buildSharesDto } from '../../utils/shares'
import {
  getCustomsBrokerLocationContext,
  getFirstSequenceOfType,
} from '../../utils/shipmentFuncs'
import { usePermissions } from '../auth'
import { billToMapper } from '../billTo/billToFuncs'
import {
  buildHandlingUnitDTO,
  hasHazardousItems,
} from '../commodities/commodityUtils'
import { customsBrokerMapper } from '../customsBroker/brokerFuncs'
import { emergencyContactMapper } from '../emergencyContact/contactFuncs'
import { isCheapestQuote } from '../quote-reason/hooks'
import { useAccountSettings } from '../settings/account-settings/hooks'
import { buildCommodities } from './helpers'
import {
  bookingFormContext,
  toLocationDTO,
  useBookingPageNavigation,
  useLocations,
} from './hooks'
import { useAwardLoads } from './mutations'
import {
  billToKey,
  type BookFormLoad,
  type BookingMode,
  type CarrierBookSettings,
  composeLocationKey,
  emergencyContactKey,
  type FormSection,
  type FormSectionKey,
  type LoadToBook,
} from './types'
import { useFormSections } from './useFormSections'

type Props = {
  children: ReactNode
  loadsToBook: LoadToBook[]
  selectedQuotes: UIQuote[]
  settings: CarrierBookSettings
  allQuotes: {
    [key: string]: UIQuote[]
  }
}

export const BookingFormProvider = ({
  children,
  loadsToBook,
  selectedQuotes,
  allQuotes,
  settings,
}: Props) => {
  const { canBook } = usePermissions()
  const { leave } = useBookingPageNavigation()
  const accountSettings = useAccountSettings()
  const awardLoads = useAwardLoads()
  const mode = useQueryParam<BookingMode>('mode')
  const isRetender = mode === 'retender'
  const pickup = loadsToBook[0].pickup
  const retenderReason = useQueryParam<AwardChangeReason>('reason')

  const { locations, setLocations } = useLocations(loadsToBook)
  const [loads, setLoads] = useState(() => buildFormLoads(loadsToBook))
  const isHazardous = hasHazardousItems(loads)
  const { location: customBrokerLoc } =
    getCustomsBrokerLocationContext(locations)
  const requireQuoteReason = accountSettings.booking
    ?.requireReasonForQuoteChoice
    ? loadsToBook.some(
        l =>
          !isCheapestQuote(
            allQuotes[l.loadId] ?? [],
            selectedQuotes.find(q => q._id === l.quoteId),
          ),
      )
    : false

  const {
    activateSection,
    activeSection,
    formSections,
    goToNextSection,
    setFormSections,
    setPickupOnlySections,
  } = useFormSections(loadsToBook, isHazardous, !!customBrokerLoc)

  const [billTo, setBillTo] = useState(() =>
    billToMapper.dtoToForm(loadsToBook[0].billTo),
  )

  const [customsBrokers, setCustomsBrokers] = useState(() =>
    (loadsToBook[0].customsBroker?.length
      ? loadsToBook[0].customsBroker
      : [{ country: 'us' as Country, companyName: '', phone: '' }]
    ).map(customsBrokerMapper.dtoToForm),
  )

  const [emergencyContact, setEmergencyContact] = useState(() =>
    emergencyContactMapper.dtoToForm(loadsToBook[0].emergencyContact),
  )

  const [tags, setTags] = useState(
    () => loadsToBook[0].tags?.map(t => t.name) ?? [],
  )

  const [bolNumber, setBolNumber] = useState(
    loadsToBook[0].bol?.bolNumber ?? '',
  )

  const [shareEmails, setShareEmails] = useState('')

  // Allows entering commodity and billTo info later
  const [isPickupOnly, setPickupOnly] = useState(false)
  const isPending = loadsToBook[0].status === 'pending'
  const hasPickupOnlyPrompt =
    mode !== 'finishBOL' &&
    !isRetender &&
    !settings.requiresFullBOLDetails &&
    (isPending || loadsToBook[0].bol?.status === 'not-requested')

  function onPickupOnlyChange(e: ChangeEvent<HTMLInputElement>) {
    const pickupOnly = e.currentTarget.checked
    setPickupOnly(e.currentTarget.checked)
    setPickupOnlySections(pickupOnly)
  }

  const setFormRef = useCallback(
    (sectionKey: FormSectionKey, ref: FormSection['ref']) => {
      setFormSections(prev =>
        prev.map(s => {
          if (s.key !== sectionKey) return s
          return { ...s, ref }
        }),
      )
    },
    [setFormSections],
  )

  function toLoadDTO({
    isPickupOnly,
    load,
  }: {
    isPickupOnly: boolean
    load: BookFormLoad
  }) {
    const dto: LoadDTO = {
      equipment: {
        accessorials:
          load.equipment.accessorials?.map(removeNullProperties) ?? [],
      },
      loadId: load.loadId,
      quoteId: load.quoteId,
    }

    if (!isPickupOnly) {
      dto.items = load.items.map(buildHandlingUnitDTO)
    }

    return dto
  }

  function bookShipment(): void {
    if (awardLoads.isLoading || awardLoads.isSuccess) return
    if (!canBook) {
      toast.error('You do not have the required permissions.')
      return
    }

    const validSectionKeys: string[] = []
    formSections.forEach(s => s.ref?.current?.checkValidity())
    const invalidSections = formSections.filter(s => !s.isValid)
    let invalidSection = invalidSections.shift()
    let isValidShipment = true

    while (invalidSection) {
      if (!invalidSection.ref?.current) {
        // Section has not been opened
        isValidShipment = false
        break
      }

      const formRef = invalidSection.ref.current
      const isValid = formRef.checkValidity()

      if (isValid) {
        validSectionKeys.push(invalidSection.key)

        const nextInvalidSection = invalidSections.shift()

        invalidSection = nextInvalidSection
      } else {
        isValidShipment = false
        submitForm(formRef)
        break
      }
    }

    if (validSectionKeys.length > 0) {
      setFormSections(prev =>
        prev.map(s => {
          if (!validSectionKeys.includes(s.key)) return s
          return { ...s, isValid: true }
        }),
      )
    }

    if (!isValidShipment) return

    if (isPending) {
      const pickupDate = dayjs(locations[0].stopDate)
      const changedPickupDate = selectedQuotes.find(q => {
        if (!q.pickupDate) return false
        const quotedPickupDate = dayjs.utc(q.pickupDate)
        return !quotedPickupDate.isSame(pickupDate, 'day')
      })?.pickupDate

      if (changedPickupDate) {
        const prev = dayjs.utc(changedPickupDate).format('MMM D')
        const next = dayjs(pickupDate).format('MMM D')
        const prompt = `You have changed the pick up date from ${prev} to ${next} which may result in a different rate than what was originally quoted. Are you sure you want to continue booking?`

        if (!window.confirm(prompt)) {
          const originKey = composeLocationKey(0)

          setFormSections(prev =>
            prev.map(s => {
              if (s.key !== originKey) return s
              return { ...s, isValid: false }
            }),
          )

          return
        }
      }
    }

    const loadsDTO: AwardLoadsDTO = {
      createBOL: !isPickupOnly,
      isEdit: isRetender || (!isPending && pickup?.status !== 'error'),
      loads: loads.map(load => toLoadDTO({ isPickupOnly, load })),
      locations: locations.map(toLocationDTO),
      bolNumber: bolNumber,
      shares: buildSharesDto(shareEmails, locations),
      tags,
      ...(!isPickupOnly && { billTo: billToMapper.formToDTO(billTo) }),
      ...(isRetender && {
        retender: true,
        ...(retenderReason && { retenderReason }),
      }),
      ...(!isPickupOnly &&
        !!customBrokerLoc && {
          customsBroker: customsBrokers.map(customsBrokerMapper.formToDTO),
        }),
      ...(!isPickupOnly &&
        isHazardous && {
          emergencyContact: emergencyContactMapper.formToDTO(emergencyContact),
        }),
    }

    awardLoads.mutate(loadsDTO, {
      onSuccess: ({ loads }) => leave(loads),
      onError: error => {
        let errorMessage = `Unable to award ${pluralize(
          'load',
          loads.length,
        )}, ${supportMessage}`

        const { dataPath, message } = (error as any)?.errors?.[0] ?? {}
        const path = dataPath?.split('.').filter(Boolean)
        const formSection = path?.[0]

        if (!!path?.length && message) {
          let sectionKey: FormSectionKey | null = null
          errorMessage = `Error at ${path.join(' ')}: ${message}`

          if (formSection === 'billTo') sectionKey = billToKey
          if (formSection === 'emergencyContact') {
            sectionKey = emergencyContactKey
          }

          if (sectionKey) {
            setFormSections(prev =>
              prev.map(s => {
                if (s.key !== sectionKey) return s
                return { ...s, isValid: false }
              }),
            )
          }
        }

        awardLoads.reset()
        toast.error(errorMessage)
      },
    })
  }

  return (
    <bookingFormContext.Provider
      value={{
        activateSection,
        activeSection,
        billTo,
        bolNumber,
        setBolNumber,
        bookShipment,
        customsBrokers,
        emergencyContact,
        formSections,
        goToNextSection,
        hasPickupOnlyPrompt,
        isBooking: awardLoads.isLoading || awardLoads.isSuccess,
        isPickupOnly,
        isRetender,
        loads,
        locations,
        onPickupOnlyChange,
        pickup,
        requireQuoteReason,
        selectedQuotes,
        setBillTo,
        setCustomsBrokers,
        setEmergencyContact,
        setFormRef,
        setFormSections,
        setLoads,
        setLocations,
        settings,
        tags,
        setTags,
        shareEmails,
        setShareEmails,
      }}
    >
      {children}
    </bookingFormContext.Provider>
  )
}

function buildFormLoads(loads: LoadToBook[]): BookFormLoad[] {
  const firstDropSequence = getFirstSequenceOfType(
    loads[0].locations,
    'delivery',
  )

  return loads.map(load => {
    const { equipment, loadId, isMultiTruck, quoteId, status, quoteRequestId } =
      load
    const equipmentAccessorials = equipment.accessorials?.slice() ?? []
    const tempSettings = equipmentAccessorials.find(a => a.key === 'temp')
    const isTempControlRequired = refrigeratedEquipmentTypes.includes(
      equipment.type,
    )

    if (isTempControlRequired && !tempSettings) {
      equipmentAccessorials.push({ key: 'temp' })
    }

    return {
      equipment: {
        ...equipment,
        accessorials: equipmentAccessorials,
      },
      items: buildCommodities(load, firstDropSequence),
      isMultiTruck,
      loadId,
      quoteId,
      quoteRequestId,
      status,
      type: equipment.type,
      weight: equipment.weight,
      weightUOM: equipment.weightUOM,
    }
  })
}
