import { useQuery } from '@tanstack/react-query'

import { type SubscriptionContextResponse } from '@fv/models'

import { shipperFetch } from '../../../utils/shipperFetch'

export const useSubscriptionContext = () => {
  return useQuery(['subscription'], () =>
    shipperFetch<SubscriptionContextResponse>('/subscription'),
  )
}
