import { useEffect, useState } from 'react'
import { useToggle } from 'usehooks-ts'

import { FvButton } from '@fv/client-components'
import { currencyToString, useFormModelState } from '@fv/client-core'
import { type SubscriptionPortalResponse } from '@fv/models'

import InfoBox from '../../../components/InfoBox'
import { ValidatedForm } from '../../../components/inputs'
import {
  InputAdornment,
  InputGroup,
} from '../../../components/inputs/InputGroup'
import { Loading } from '../../../components/Loading'
import { shipperFetch } from '../../../utils/shipperFetch'
import { SettingsPageLayout } from '../SettingsPageLayout'
import { SettingsSection } from '../SettingsSection'
import { useSubscriptionContext } from './queries'

export const SubscriptionPage = () => {
  const query = useSubscriptionContext()

  if (query.isLoading) {
    return (
      <SettingsPageLayout>
        <Loading />
      </SettingsPageLayout>
    )
  }

  return (
    <SettingsPageLayout>
      <StripeFundingInstructions />
      <ReceiptEmailsForm />
      <MangePaymentOptions />
    </SettingsPageLayout>
  )
}

const MangePaymentOptions = () => {
  const [loading, toggleLoading] = useToggle()
  const handleClick = async () => {
    toggleLoading()
    const response =
      await shipperFetch<SubscriptionPortalResponse>(`/subscription/portal`)
    window.location.href = response.redirect
  }
  return (
    <SettingsSection title="Payment options">
      <FvButton loading={loading} theme="primary" onClick={handleClick}>
        Manage my payment options
      </FvButton>
    </SettingsSection>
  )
}

const ReceiptEmailsForm = () => {
  const query = useSubscriptionContext()
  const { register } = useFormModelState({
    initialValue: { emails: query.data.receiptEmails?.join(',') ?? '' },
  })

  const handleSubmit = () => {
    console.log('here')
  }
  return (
    <SettingsSection title="Email receipts to">
      <ValidatedForm onValidSubmit={handleSubmit}>
        <InputGroup
          inputType="text"
          label="Emails"
          inputProps={{
            ...register('emails'),
          }}
          endContent={<FvButton type="submit" theme="primary" icon="check" />}
        />
      </ValidatedForm>
    </SettingsSection>
  )
}

const StripeFundingInstructions = () => {
  const query = useSubscriptionContext()
  const funding = query.data?.funding
  if (!funding) return null
  return (
    <SettingsSection title="Your funding information">
      <InputGroup
        label="Routing number"
        inputType="text"
        inputProps={{
          name: 'routingNumber',
          readOnly: true,
          disabled: true,
          value: funding.routingNumber,
        }}
      />
      <InputGroup
        label="Account number"
        inputType="text"
        inputProps={{
          name: 'accountNumber',
          readOnly: true,
          disabled: true,
          value: funding.accountNumber,
        }}
      />
      <InputGroup
        label="Balance"
        inputType="amount"
        startContent={<InputAdornment position="start" icon="dollar-sign" />}
        inputProps={{
          isPrice: true,
          name: 'balance',
          readOnly: true,
          disabled: true,
          value: currencyToString((query.data.balance ?? 0) / 100, true),
        }}
      />
    </SettingsSection>
  )
}

const StripePortalRedirect = () => {
  const [error, setError] = useState<string>()
  useEffect(() => {
    const fetchAndRedirect = async () => {
      const response =
        await shipperFetch<SubscriptionPortalResponse>(`/subscription/portal`)
      window.location.href = response.redirect
    }

    fetchAndRedirect().catch(() => {
      setError(
        'There was an error, please refresh the page.  If this error continues please reach out to support',
      )
    })
  })
  return (
    <>
      {error && <InfoBox>{error}</InfoBox>}
      <span>
        One second while we take you to Stripe to manage your payment options...
      </span>
    </>
  )
}
